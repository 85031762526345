var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-wrap wrapper"},[(_vm.loading)?_c('BaseLoad'):_c('div',{staticClass:"list"},[_c('h2',[_vm._v("我的收藏")]),_c('div',{staticClass:"my-favor"},[_c('div',{staticClass:"between"},[_c('div',{staticClass:"sub-nav"},_vm._l((_vm.subTab),function(item,index){return _c('li',{key:item,class:[index == 0 ? 'sub-act' : ''],on:{"click":function($event){return _vm.changeSubTab(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('p',{staticClass:"sub-tip"},[_c('span',[_vm._v("课件")]),_c('span',{staticClass:"sub-tip-total"},[_vm._v(" "+_vm._s(_vm.total)+" ")]),_vm._v("个 ")])]),_c('div',{staticClass:"line"}),(_vm.listLoad && !_vm.loading)?_c('ListLoad'):_vm._e(),(!_vm.listLoad)?_c('div',{staticClass:"my-favor-con"},[_c('div',{staticClass:"course-list"},[(_vm.list.length > 0)?_c('div',{staticClass:"flex"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"course-list-item"},[_c('p',{staticClass:"delete flex",class:[item.show ? 'visible' : 'hide']},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.handleFavorDel(item.col_id, 1)}}},[_c('i',{staticClass:"iconfont icon-shanchu"}),_vm._v(" 删除 ")])]),_c('li',{style:({
                  background:
                    'url(' +
                    _vm.preSrc +
                    item.cover +
                    ') center center no-repeat',
                  backgroundSize: 'contain',
                }),on:{"mouseenter":function($event){return _vm.handleMouseEnter(index)},"click":function($event){$event.preventDefault();return _vm.toPages(item.id, item.type, item.title)}}},[_c('p',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.name))])])])}),0):_vm._e()])]):_vm._e()],1),(!_vm.listLoad && !_vm.loading && _vm.list.length == 0)?_c('BaseEmpty'):_vm._e(),(_vm.list.length > 0 && _vm.total > 10)?_c('div',{staticClass:"pagination-con"},[_c('el-pagination',{attrs:{"page-size":_vm.pageSize,"pager-count":5,"layout":"prev, pager, next","total":_vm.total,"current-page":_vm.page},on:{"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }